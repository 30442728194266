import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { ButtonDirective } from '@husky/app/shared/ui-button';

import { AppCoreMaintenanceService } from './app-core-maintenance.service';

@Component({
  selector: 'husky-app-core-maintenance',
  standalone: true,
  imports: [CommonModule, ButtonDirective],
  templateUrl: './app-core-maintenance.component.html',
  styleUrl: './app-core-maintenance.component.scss',
})
export class AppCoreMaintenanceComponent {
  state$ = this.maintenanceService.maintenanceState$;

  constructor(private maintenanceService: AppCoreMaintenanceService) {}

  refresh() {
    this.maintenanceService.refreshPage();
  }
}
