import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

/** HammerJS is used to handle touch gestures. */
import 'hammerjs';
import { Settings } from 'luxon';

/** BigInt is used to handle large integers and this patches the JSON */
import '@husky/shared/util-bigint';

import { registerAgGridModules } from './ag-grid';
import { AppModule } from './app/app.module';

Settings.defaultLocale = 'de-DE';
Settings.defaultZone = 'Europe/Berlin';

registerAgGridModules();

const environment = process.env['HUSKY_ENVIRONMENT'] ?? 'development';

Sentry.init({
  dsn: 'https://3d6e9598920ff18e4293b45a802fc419@o4507284442710016.ingest.de.sentry.io/4507284759707728',
  // environment is set at build time by the CI/CD pipeline
  environment: environment,
  release: process.env['HUSKY_PACKAGE_VERSION'] ?? 'local',
  enabled: environment !== 'development',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/app.endlich\.pro\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 100%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
