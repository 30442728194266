<div class="relative flex flex-none items-center justify-between gap-4 p-4">
  <img class="h-[50px]" src="assets/img/logo.svg" alt="Logo" />
</div>

<div class="flex-1 overflow-y-auto pt-2">
  <!--
  <husky-sidenav-item path="/"
                      label="Home"
                      [routerLinkActiveOptions]="{ exact: true }"></husky-sidenav-item>
                      -->

  @if (canListActivities()) {
    <husky-sidenav-item
      [routerLinkActiveOptions]="{ exact: true }"
      path="/activity"
      label="Aktivitäten"
      icon="event"></husky-sidenav-item>
  }

  @if (canListActivities()) {
    <husky-sidenav-item
      path="/activity/gantt"
      label="Terminplan"
      icon="view_timeline"></husky-sidenav-item>
  }

  @if (canListContacts()) {
    <husky-sidenav-item
      path="/sales/contact"
      label="Kontakte"
      icon="person"></husky-sidenav-item>
  }

  @if (canListProjects()) {
    <husky-sidenav-item
      path="/sales/project"
      label="Projekte"
      icon="target"></husky-sidenav-item>
  }

  @if (canListTimeEntry()) {
    <husky-sidenav-item
      path="/hr/time-entry"
      label="Zeiterfassung"
      icon="schedule"></husky-sidenav-item>
  }

  @if (canCreateQuickEstimate()) {
    <husky-sidenav-item
      path="/sales/quick-estimate"
      label="PV-Preisrechner"
      icon="calculate"></husky-sidenav-item>
  }

  <!--
  @if (canListInvoices()) {
    <husky-sidenav-item path="/accounting/invoice" label="Rechnungen" icon="point_of_sale"></husky-sidenav-item>
  }
  -->

  @if (canListPurchaseInvoice()) {
    <husky-sidenav-item
      path="/accounting/purchase-invoice"
      label="Eingangsrechnungen"
      icon="checkbook"></husky-sidenav-item>
  }

  @if (canListEmployee()) {
    <husky-sidenav-item
      path="/hr/employee"
      label="Mitarbeiter"
      icon="badge"></husky-sidenav-item>
  }

  @if (
    canListProducts() ||
    canListWarehouseReceipt() ||
    canListPurchaseOrder() ||
    canListSuppliers()
  ) {
    <husky-sidenav-item-group label="Einkauf & Lager" icon="warehouse">
      @if (canListSuppliers()) {
        <husky-sidenav-item
          path="/inventory/supplier"
          label="Lieferanten"
          icon="orders"></husky-sidenav-item>
      }
      @if (canListProducts()) {
        <husky-sidenav-item
          path="/inventory/product"
          label="Produkte"
          icon="inventory_2"></husky-sidenav-item>
      }
      @if (canListPurchaseOrder()) {
        <husky-sidenav-item
          path="/inventory/purchase-order"
          label="Bestellungen"
          icon="shopping_basket"></husky-sidenav-item>
      }
      @if (canListWarehouseReceipt()) {
        <husky-sidenav-item
          path="/inventory/warehouse-receipt"
          label="Wareneingänge"
          icon="local_shipping"></husky-sidenav-item>
      }
    </husky-sidenav-item-group>
  }

  @if (canListUsers() || canReadHealthStatus()) {
    <husky-sidenav-item-group label="Einstellungen" icon="settings">
      @if (canListUsers()) {
        <husky-sidenav-item
          path="/setting/user"
          label="Benutzer"
          icon="supervisor_account"></husky-sidenav-item>
      }

      @if (canReadHealthStatus()) {
        <husky-sidenav-item
          path="/setting/health-status"
          label="Health Status"
          icon="spa"></husky-sidenav-item>
      }
    </husky-sidenav-item-group>
  }

  <!--
  <husky-sidenav-item-group label="Vertrieb">
     <husky-sidenav-item path="/sales/quote" label="Angebote"></husky-sidenav-item>
  </husky-sidenav-item-group>
  -->

  <!--
  <husky-sidenav-item-group label="Buchhaltung">
    <husky-sidenav-item path="/accounting/customer" label="Kunden"></husky-sidenav-item>
    <husky-sidenav-item path="/accounting/invoice" label="Verkaufsrechnungen"></husky-sidenav-item>
    <husky-sidenav-item path="/accounting/purchase-invoice" label="Eingangsrechnungen"></husky-sidenav-item>
    <husky-sidenav-item path="/accounting/banking" label="Banking"></husky-sidenav-item>
  </husky-sidenav-item-group>

  <husky-sidenav-item-group label="Projektmanagement">
    <husky-sidenav-item path="/project" label="Projekte"></husky-sidenav-item>
  </husky-sidenav-item-group>
  -->
</div>

<div class="flex-none">
  <a
    class="sidenav-item flex cursor-pointer items-center gap-3 px-4 py-2.5 font-medium transition hover:bg-gray-600/10"
    (click)="reload()">
    <mat-icon>refresh</mat-icon>
    <span>App neu laden</span>
  </a>

  <div
    class="m-2 flex cursor-pointer items-center gap-3 truncate rounded-xl p-2 transition hover:bg-gray-100"
    [matMenuTriggerFor]="userMenu">
    <husky-app-shared-ui-letter-avatar
      class="flex-none"
      [name]="
        user()?.firstName + ' ' + user()?.lastName
      "></husky-app-shared-ui-letter-avatar>
    <div class="flex-1 truncate">
      <div class="truncate text-sm font-bold">
        {{ user()?.firstName }} {{ user()?.lastName }}
      </div>
      <div class="truncate text-xs text-gray-500">{{ user()?.email }}</div>
    </div>
    <mat-icon class="flex-none text-gray-500">expand_more</mat-icon>
  </div>

  <mat-menu #userMenu="matMenu">
    <button (click)="logout()" mat-menu-item>
      <mat-icon>exit_to_app</mat-icon>
      <span>Abmelden</span>
    </button>
  </mat-menu>
</div>
