@if (state$ | async; as state) {
  @if (state.isInMaintenance || state.hasError) {
    <div
      class="fixed inset-0 bottom-0 left-0 right-0 top-0 flex h-full w-full items-center justify-center bg-black/70"
      style="z-index: 999999">
      <div class="mx-4 max-w-md rounded-lg bg-white p-8 text-center shadow-xl">
        @if (state.isInMaintenance) {
          <div>
            <div
              class="mx-auto mb-4 h-12 w-12 animate-spin rounded-full border-4 border-blue-500 border-t-transparent"></div>
            <h2 class="mb-4 text-2xl font-semibold text-gray-800">
              Update läuft&hellip;
            </h2>
            <p class="mb-2 text-gray-600">
              Die App wird gerade aktualisiert. Bitte warten...
            </p>
            <p class="text-gray-600">
              Dies dauert normalerweise weniger als 1 Minute.
            </p>
          </div>
        }

        @if (state.hasError) {
          <div>
            <h2 class="mb-4 text-2xl font-semibold text-gray-800">
              Etwas ist schiefgelaufen
            </h2>
            <p class="mb-2 text-gray-600">
              Wir konnten nach mehreren Versuchen keine Verbindung zur App
              herstellen.
            </p>
            <p class="mb-4 text-gray-600">
              Bitte aktualisieren Sie die Seite oder wenden Sie sich an Ihren
              Administrator, wenn das Problem weiterhin besteht.
            </p>
            <button
              class="mt-4"
              (click)="refresh()"
              huskyButton
              color="primary">
              Seite aktualisieren
            </button>
          </div>
        }
      </div>
    </div>
  }
}
